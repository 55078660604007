import { t } from 'i18next';
import React, { useState } from 'react'
import { ChecklistSectionForm } from './checklist-section-form';
import { handleAddQuestionForm, createSection, editSection, handleCloseQuestionForm, handleCloseSectionForm, handleEdit, handleEditSection, handleRemove, handleRemoveSection } from './checklist-helpers';
import { Button, QuestionTree, Table, FormControl,Input, IconButton } from 'fiorde-fe-components';
import { ChecklistSectionColumns } from './checklist-section-columns';
import { ChecklistQuestionForm } from './checklist-question-form';
import { Row } from '../row/row';
import { TrashIcon } from '../icons';
import {CHECKLIST_QUESTIONS_QUESTION_TREE, CHECKLIST_SECTIONCOLUMNS_BUTTON_REMOVE, CHECKLIST_SECTIONFORM_INPUT_NAME, CHECKLIST_SECTIONFORM_INPUT_NAME_LABEL, CHECKLIST_QUESTIONS_LABEL_TITLE, CHECKLIST_SECTIONSQUESTIONS_BUTTON_ADDSECTION, CHECKLIST_SECTIONSQUESTIONS_LABEL_TITLE } from '@/ids';

export function ChecklistSectionsQuestionsContainer({
  visibleSectionForm,
  sectionForm,
  setSectionForm,
  sections,
  setSections,
  setVisibleSectionForm,
  visibleQuestionForm,
  sectionEditable,
  typeFieldList,
  questionForm,
  setQuestionForm,
  questions,
  setQuestions,
  setVisibleQuestionForm,
  setSectionEditable
}) {
  const [open, setOpen] = useState(false);
  const [conditional, setConditional] = useState(null);

  const changeAnswer = (answers, data, indexQuestion, section) => {
    return {
      ...answers,
      isThereSubQuestion: true,
      subquestion: {
        ...data,
        id: CHECKLIST_QUESTIONS_QUESTION_TREE+indexQuestion,
        question: data.name,
        answers: data?.answers ? [...data.answers] : [],
        onEdit: (index, section) => handleEdit(index, section, questions, setQuestionForm, setVisibleQuestionForm, setSectionEditable, setOpen, setConditional),
        onRemove: (index, section) => handleRemove(index, section, questions, setQuestions),
        index: indexQuestion,
        section
      },
    }
  }

  const saveConditional = (data, {indexQuestion, indexAnswer, section}) => {
    const numbersQuestion = indexQuestion.split('.')
    const newQuestions = questions.map((question) => {
      if(question.section.index === section && question.index === Number(numbersQuestion[0])-1) {
        let newQuestion = question
        numbersQuestion.forEach((n, i) => {
          let newAnswer;
          if(i > 0) {
            newAnswer = newQuestion.answers[Number(n)-1]
          }
          if(newAnswer?.subquestion) {
            newQuestion = newAnswer.subquestion
          }
        })
        newQuestion.answers[Number(indexAnswer)-1] = changeAnswer(newQuestion.answers[Number(indexAnswer)-1], data, indexQuestion, section)
      }
      
      return question
    })
    setQuestions([...newQuestions])
  }
  return (
    <>
      <div
        style={{marginTop: '20px'}}
        id={CHECKLIST_SECTIONSQUESTIONS_LABEL_TITLE}
      >
        {t("components.add-checklist.sections-and-questions")}
      </div>
      <div style={{ margin: "20px 10px 10px 10px" }}>
        {sections.map((section, index) =>
          <div key={index} style={{marginBottom: '10px'}}>
            <div style={{display: 'grid', gridTemplateColumns: '1fr auto', gap: '8px'}}>
              <FormControl
                label={t("components.add-checklist.section-columns.section") + ' ' + (index+1)}
                id={CHECKLIST_SECTIONFORM_INPUT_NAME_LABEL}
              >
                <Input
                  inputProps={{ "data-testid": "section-name" }}
                  id={CHECKLIST_SECTIONFORM_INPUT_NAME}
                  onChange={(value: string) =>
                    editSection(value, index, sections, setSections)
                  }
                  value={sections[index].name}
                  placeholder={t("components.add-checklist.name-placeholder")}
                />
              </FormControl>
              <div style={{marginTop: '22px'}}>
                <IconButton
                  id={CHECKLIST_SECTIONCOLUMNS_BUTTON_REMOVE}
                  data-testid="remove-button"
                  onClick={() => handleRemoveSection(sections, index, setSections, questions, setQuestions)}
                  tooltip={t(
                    "components.add-checklist.condition-columns.remove-tooltip"
                  )}
                >
                  <TrashIcon />
                </IconButton>
              </div>
            </div>
            <div
              style={{margin: '10px'}}
              id={CHECKLIST_QUESTIONS_LABEL_TITLE}
            >
              {t("components.add-checklist.questions")}
            </div>
            <div style={{marginLeft: '10px', backgroundColor: '#F8F8F8', width: '98%', padding: '10px'}}>
            {questions.map((question, index) =>  question?.section?.index === section?.index && (
                  <QuestionTree
                    key={index}
                    content={[
                      {
                        id: CHECKLIST_QUESTIONS_QUESTION_TREE+index,
                        question: question.name,
                        answers: question?.answers ? [...question.answers] : [],
                        onEdit: (index, section) => handleEdit(index, section, questions, setQuestionForm, setVisibleQuestionForm, setSectionEditable, setOpen, setConditional),
                        onRemove: (index, section) => handleRemove(index, section, questions, setQuestions),
                        index: index,
                        section: section?.index
                      },
                    ]}
                  />
              ))
            } 
            <ChecklistQuestionForm
                sectionEditable={section}
                typeFieldList={typeFieldList}
                values={questionForm}
                onChange={(value) => setQuestionForm(value)}
                open={open}
                setOpen={setOpen}
                saveConditional={saveConditional}
                conditional={conditional}
                setConditional={setConditional}
                onSubmit={(data) =>
                  handleAddQuestionForm(
                    questionForm,
                    questions,
                    setQuestions,
                    () =>
                      handleCloseQuestionForm(
                        setQuestionForm,
                        setVisibleQuestionForm
                      ),
                      data
                  )
                }
                onClose={() =>
                  handleCloseQuestionForm(
                    setQuestionForm,
                    setVisibleQuestionForm
                  )
                }
                disabledCloseButton={!questions.length}
              />
            </div>
          </div>
        )}
        <div style={{marginTop: '10px'}}>
          <Button
            id={CHECKLIST_SECTIONSQUESTIONS_BUTTON_ADDSECTION}
            data-testid="new-section-button"
            onAction={() => createSection(sections, setSections, sectionForm)}
            text={t("components.add-checklist.new-section-button")}
            backgroundGreen={false}
          />
        </div>
      </div>
    </>
  )
}