import React from "react";
import { AddChecklist } from "@/presentation/pages";
import {
  makeRemoteAddChecklist,
  makeRemoteLoadConditionList,
  makeRemoteLoadOperatorList,
  makeRemoteLoadTypeFieldList,
  makeRemoteLoadSystemList
} from "../../usecases";

export const makeChecklistAdd: React.FC = (props) => {
  return (
    <AddChecklist
      {...props}
      addChecklist={makeRemoteAddChecklist()}
      loadOperatorList={makeRemoteLoadOperatorList}
      loadConditionList={makeRemoteLoadConditionList()}
      loadTypeFieldList={makeRemoteLoadTypeFieldList()}
      loadSystemList={makeRemoteLoadSystemList()}
    />
  );
};
