import React from "react";
import { UpdateChecklist } from "@/presentation/pages";
import {
  makeRemoteLoadConditionList,
  makeRemoteLoadOperatorList,
  makeRemoteLoadTypeFieldList,
  makeRemoteLoadSystemList,
  makeRemoteUpdateChecklist,
  makeRemoteLoadChecklist
} from "../../usecases";

export const makeChecklistUpdate: React.FC = (props) => {
  return (
    <UpdateChecklist
      {...props}
      updateChecklist={makeRemoteUpdateChecklist()}
      loadChecklist={makeRemoteLoadChecklist()}
      loadOperatorList={makeRemoteLoadOperatorList}
      loadConditionList={makeRemoteLoadConditionList()}
      loadTypeFieldList={makeRemoteLoadTypeFieldList()}
      loadSystemList={makeRemoteLoadSystemList()}
    />
  );
};
