import React from "react";
import { Checklist } from "@/presentation/pages";
import {
  makeRemoteLoadChecklist,
  makeRemoteLoadChecklistList,
  makeRemoteUpdateChecklist,
} from "../../usecases";

export const makeChecklistList: React.FC = (props) => {
  return (
    <Checklist
      {...props}
      loadChecklist={makeRemoteLoadChecklist()}
      loadChecklistList={makeRemoteLoadChecklistList()}
      updateChecklist={makeRemoteUpdateChecklist()}
    />
  );
};
