import React, { useState } from "react";
import {
  AddChecklist as DomainChecklist,
  LoadChecklistList,
  UpdateChecklist as DomainUpdateChecklist,
  LoadChecklist,
} from "@/domain/usecase";
import {
  LabelCell,
  ProgressIndicator,
  Pagination,
  Table,
  Button,
  IconButton
} from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import {
  PageHeader,
  DisableChecklist,
} from "@/presentation/components";
import { t } from "i18next";
import { CHECKLIST_ADDCHECKLIST_BUTTON_OPEN, CHECKLIST_UPDATECHECKLIST_BUTTON_OPEN } from "@/ids";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "@/presentation/components";


type ChecklistListProps = {
  loadChecklist: LoadChecklist;
  loadChecklistList: LoadChecklistList;
  updateChecklist: DomainUpdateChecklist;
};

const columns = (
  updateChecklist: DomainUpdateChecklist,
  loadChecklist: LoadChecklist,
  navigate: Function,
) => [
  {
    label: "Checklist",
    key: "name",
    render: ({ name }: any) => <LabelCell>{name}</LabelCell>,
    size: 12,
  },
  {
    label: "Menu",
    key: "menu",
    render: ({ id, isActive }) => (
      <div style={{ display: "flex", gap: 8 }}>
        <IconButton
          data-testid="open-button"
          onClick={() => navigate(`/checklist/edit/${id}`)}
          tooltip={t("components.update-checklist.open-button-tooltip")}
          id={CHECKLIST_UPDATECHECKLIST_BUTTON_OPEN}
        >
          <EditIcon />
        </IconButton>
        <DisableChecklist
          id={id}
          checked={isActive}
          loadChecklist={loadChecklist}
          updateChecklist={updateChecklist}
        />
      </div>
    ),
    size: 0,
  },
];

export function Checklist({
  loadChecklist,
  loadChecklistList,
  updateChecklist,
}: ChecklistListProps) {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc",
  });
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ["checklist-list", filter],
    queryFn: async () => loadChecklistList.load(filter),
  });
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title="Checklist">
        <Button
          id={CHECKLIST_ADDCHECKLIST_BUTTON_OPEN}
          icon="add"
          data-testid="open-button"
          onAction={() => navigate("/checklist/add")}
          text={t("components.add-checklist.open-button")}
        />
      </PageHeader>
      <Table
        rows={data?.content || []}
        columns={columns(
          updateChecklist,
          loadChecklist,
          navigate
        )}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Checklist por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0,
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
