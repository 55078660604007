import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { RouteWrapper } from "../route-wrapper/route-wrapper";
import { makeChecklistUpdate, makeChecklistList, makeChecklistAdd } from "@/main/factories/pages";

export function Router() {
  return (
    <HashRouter basename="/checklist">
      <Routes>
        <Route
          path="/"
          element={<RouteWrapper makeComponent={makeChecklistList} />}
        />
        <Route
          path="/checklist/add"
          element={<RouteWrapper makeComponent={makeChecklistAdd} />}
        />
        <Route
          path="/checklist/edit/:id"
          element={<RouteWrapper makeComponent={makeChecklistUpdate} />}
        />
      </Routes>
    </HashRouter>
  );
}
