import React, { useState, useEffect } from "react";
import {
  UpdateChecklist as DomainUpdateChecklist,
  LoadChecklist,
  LoadConditionList,
  LoadTypeFieldList,
  LoadSystemList,
} from "@/domain/usecase";
import { Row } from "@/presentation/components/row/row";
import {
  Paper,
  Messages,
  Button
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { parsePayload, createPayload } from "@/presentation/components/checklist/checklist-helpers";
import { ChecklistGeneralForm } from "@/presentation/components/checklist/checklist-general-form";
import { ChecklistConditionsContainer } from "@/presentation/components/checklist/checklist-conditions-container";
import { ChecklistSectionsQuestionsContainer } from "@/presentation/components/checklist/checklist-sections-questions-container";
import { CHECKLIST_UPDATECHECKLIST_LABEL_MESSAGE, CHECKLIST_UPDATECHECKLIST_PAPER, CHECKLIST_ADDCHECKLIST_BUTTON_CANCEL, CHECKLIST_ADDCHECKLIST_BUTTON_SUBMIT } from "@/ids";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

type UpdateChecklistProps = {
  updateChecklist: DomainUpdateChecklist;
  loadChecklist: LoadChecklist;
  loadOperatorList: any;
  loadConditionList: LoadConditionList;
  loadTypeFieldList: LoadTypeFieldList;
  loadSystemList: LoadSystemList;
};

const initialQuestionForm = {
  index: null,
  name: "",
  questionType: null,
  description: "",
  isRequired: false,
  isConditional: false,
  answers: [],
  section: 0
};

export function UpdateChecklist({
  updateChecklist,
  loadChecklist,
  loadOperatorList,
  loadConditionList,
  loadTypeFieldList,
  loadSystemList
}: UpdateChecklistProps) {
  const { id } = useParams();
  const numberId =  Number(id);
  const queryClient = useQueryClient();
  const [questionForm, setQuestionForm] = useState(initialQuestionForm);
  const [sectionForm, setSectionForm] = useState({
    index: null,
    name: "",
  });
  const [generalForm, setGeneralForm] = useState({
    name: '',
    systems: []
  })
  const [enableConditions, setEnableConditions] = useState(false);
  const [sections, setSections] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [visibleForm, setVisibleForm] = useState(false);
  const [visibleSectionForm, setVisibleSectionForm] = useState(false);
  const [visibleQuestionForm, setVisibleQuestionForm] = useState(false);
  const [sectionEditable, setSectionEditable] = useState();
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });
  const [conditionForm, setConditionForm] = useState({
    index: null,
    conditionType: { id: null },
    operator: '',
  });
  const navigate = useNavigate();
  const { data } = useQuery({
    queryKey: [`checklist-${numberId}`],
    queryFn: async () => await loadChecklist.load(numberId),
    cacheTime: 0,
  });

  useEffect(() => {
    if(data) {
      const { conditions, questions, sections, name, systems } = parsePayload(data)
      setConditions(conditions)
      setGeneralForm({
        name: name,
        systems
      })
      setQuestions(questions)
      setSections(sections)
    }
  }, [data])



  const { data: conditionsList = [] } = useQuery({
    queryKey: ["condition-list"],
    queryFn: async () => await loadConditionList.load(),
  });

  
  const { data: typeFieldList = [] } = useQuery({
    queryKey: ["type-field-list"],
    queryFn: async () => await loadTypeFieldList.load(),
  });

  const { data: systemList = [] } = useQuery({
    queryKey: ["system-list"],
    queryFn: async () => await loadSystemList.load(),
  });

  const { data: operatorList = [] } = useQuery({
    queryKey: ["operator-list", conditionForm],
    queryFn: async () => await loadOperatorList(conditionsList.find((condition) =>  condition.id === conditionForm?.conditionType?.id)?.endpoint).load(),
    enabled: conditionsList.length > 0 && conditionForm.conditionType.id !== null,
  });

  function handleSubmit(): void {
    updateChecklist
      .update(numberId, createPayload(questions, conditions, sections, generalForm, data?.isActive))
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["checklist-list"] });
        handleClose();
        setResponseMessage({
          message: t("components.update-checklist.response-success-message"),
          severity: "success",
        });
      })
      .catch((err) => {
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      });
  }

  function handleClose() {
    navigate("/")
  }

  return (
    <Paper id={CHECKLIST_UPDATECHECKLIST_PAPER}>
      <div style={{minHeight: '80vh'}}>
        <ChecklistGeneralForm
          values={generalForm}
          onChange={(value) => setGeneralForm(value)}
          systemList={systemList}
        />
        <ChecklistConditionsContainer
          enableConditions={enableConditions}
          setEnableConditions={setEnableConditions}
          visibleForm={visibleForm}
          conditions={conditions}
          setConditions={setConditions}
          conditionForm={conditionForm}
          setVisibleForm={setVisibleForm}
          setConditionForm={setConditionForm}
          conditionsList={conditionsList}
          operatorList={operatorList}
        />
        <ChecklistSectionsQuestionsContainer
          visibleSectionForm={visibleSectionForm}
          sectionForm={sectionForm}
          setSectionForm={setSectionForm}
          sections={sections}
          setSections={setSections}
          setVisibleSectionForm={setVisibleSectionForm}
          visibleQuestionForm={visibleQuestionForm}
          sectionEditable={sectionEditable}
          typeFieldList={typeFieldList}
          questionForm={questionForm}
          setQuestionForm={setQuestionForm}
          questions={questions}
          setQuestions={setQuestions}
          setVisibleQuestionForm={setVisibleQuestionForm}
          setSectionEditable={setSectionEditable}
        />
      {responseMessage.message && (
        <Messages
          id={CHECKLIST_UPDATECHECKLIST_LABEL_MESSAGE}
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
      </div>
      <Row gridTemplateColumns="1fr auto" justifyItems="end">
        <Button
          id={CHECKLIST_ADDCHECKLIST_BUTTON_CANCEL}
          data-testid="close-button"
          backgroundGreen={false}
          onAction={() => handleClose()}
          text={t("components.add-checklist.close-button")}
        />
        <Button
          id={CHECKLIST_ADDCHECKLIST_BUTTON_SUBMIT}
          data-testid="submit-button"
          disabled={questions.length < 1}
          onAction={() => handleSubmit()}
          text={t("components.add-checklist.submit-button")}
        />
      </Row>
    </Paper>
  );
}
