import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadOperatorList } from "@/domain/usecase";
import { RemoteLoadOperatorListDTO } from "../models";

export class RemoteLoadOperatorList implements LoadOperatorList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteLoadOperatorList.Model>
  ) {}

  async load(): Promise<LoadOperatorList.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "get",
    });
    const LoadClassificationList = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadClassificationList;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteLoadOperatorList {
  export type Model = RemoteLoadOperatorListDTO;
}
