import { OPERATOR } from "@/main/enums/checklist";

export function handleRemoveCondition(conditions, index, setConditions) {
  setConditions([...conditions.filter((_, i) => i !== index)]);
}

export function handleRemoveSection(sections, index, setSections, questions, setQuestions) {
  setSections([...sections.filter((_, i) => i !== index)]);
  setQuestions([...questions.filter((question) => question.section.index !== index)])
}

export function handleAddSectionForm(
  sectionForm,
  sections,
  setSections,
  handleCloseForm
) {
  if (sectionForm.index !== null) {
    const getSections = sections;
    getSections[sectionForm.index] = sectionForm;
    setSections([...getSections]);
  } else {
    const getSections = sections;
    getSections.push({...sectionForm, index: sections.length});
    setSections([...getSections]);
  }
  handleCloseForm();
}

export function createSection(sections, setSections, sectionForm) {
  const getSections = sections;
  getSections.push({...sectionForm, index: sections.length});
  setSections([...getSections]);
}

export function editSection(value, index, sections, setSections) {
  const getSections = sections;
  getSections[index] = { name: value, index};
  setSections([...getSections]);
}

export function handleAddQuestionForm(
  questionForm,
  questions,
  setQuestions,
  handleCloseForm,
  data
) {
  if (data.index !== null) {
    const getQuestions = questions;
    getQuestions[data.index] = data;
    setQuestions([...getQuestions]);
  } else {
    const getQuestions = questions;
    getQuestions.push({...data, index: questions.length});
    setQuestions([...getQuestions]);
  }
  handleCloseForm();
}

export function handleCloseSectionForm(setSectionForm, setVisibleSectionForm) {
  setSectionForm({
    index: null,
    name: "",
  });
  setVisibleSectionForm(false);
}

export function handleCloseQuestionForm(
  setQuestionForm,
  setVisibleQuestionForm
) {
  setQuestionForm({
    index: null,
    text: "",
    description: "",
    mandatory: "",
    isConditional: "",
  });
  setVisibleQuestionForm(false);
}

export function handleAddForm(
  conditionForm,
  conditions,
  setConditions,
  handleCloseForm
) {
  if (conditionForm.index !== null) {
    const getConditions = conditions;
    getConditions[conditionForm.index] = conditionForm;
    setConditions([...getConditions]);
  } else {
    const getConditions = conditions;
    getConditions.push(conditionForm);
    setConditions([...getConditions]);
  }
  handleCloseForm();
}

export function handleCloseForm(setConditionForm, setVisibleForm) {
  setConditionForm({
    index: null,
    conditionType: { id: null },
    operator: { id: null },
  });
  setVisibleForm(false);
}

export function handleEditCondition(
  condition,
  index,
  setConditionForm,
  setVisibleForm
) {
  setConditionForm({
    ...condition,
    index: index,
  });
  setVisibleForm(true);
}

export function handleEditSection(
  section,
  index,
  setSectionForm,
  setVisibleSectionForm
) {
  setSectionForm({
    ...section,
    index: index,
  });
  setVisibleSectionForm(true);
}

export function disableAddForm(conditionForm) {
  if (!conditionForm.conditionType?.id) return true;
  if (!conditionForm.value?.id) return true;
}

export function disableAddSectionForm(sectionForm) {
  if (!sectionForm.name) return true;
}

export function addConditional(indexQuestion: string, indexAnswer: string, section: number, setOpen, setConditional) {
  setOpen(true)
  setConditional({indexQuestion, indexAnswer, section})
}

export function handleEdit(index: string, section: number, questions, setQuestionForm, setVisibleQuestionForm, setSectionEditable, setOpen, setConditional) {
  const numbersQuestion = index.split('.')
  let editQuestion;

  if(numbersQuestion.length > 1) {
    questions.forEach((question) => {
      if(question.section.index === section && question.index === Number(numbersQuestion[0])-1) {
        let newQuestion = question
        numbersQuestion.forEach((n, i) => {
          let newAnswer;
          if(i > 0) {
            newAnswer = newQuestion.answers[Number(n)-1]
          }
          if(newAnswer?.subquestion) {
            newQuestion = newAnswer.subquestion
          }
        })
        editQuestion = newQuestion
      }
    })
    setOpen(true)
    setConditional({indexQuestion: index.slice(0, -2), indexAnswer: index.slice(-1), section})
    setQuestionForm({...editQuestion})
  } else {
    editQuestion = questions.find((question) => question.index === (Number(index)-1))
    setVisibleQuestionForm(true)
    setOpen(true)
    setQuestionForm({...editQuestion, index: (Number(index)-1)})
  }
  setSectionEditable(editQuestion?.section)
}

export function handleRemove(index: string, section: number, questions, setQuestions) { 
  let newQuestions = questions;
  const numbersQuestion = index.split('.')

  if(numbersQuestion.length > 1) {
    newQuestions.forEach((question) => {
      if(question.section.index === section && question.index === Number(numbersQuestion[0])-1) {
        let newQuestion = question
        let newAnswer;
        numbersQuestion.forEach((n, i) => {
          if(i > 0) {
            newAnswer = newQuestion.answers[Number(n)-1]
          }
          if(newAnswer?.subquestion) {
            newQuestion = newAnswer.subquestion
          }
        })
        delete newAnswer.subquestion
        newAnswer.isThereSubQuestion = false
      }
    })
  } else {
    newQuestions = questions.filter((question) => question.index !== Number(index)-1);
  }
  setQuestions(newQuestions)
}

export function createQuestion(question) {
 return {
    description: question.description,
    name: question.name,
    isRequired: question.isRequired,
    questionType: question.questionType,
    alternatives: question?.answers ? question.answers.map((alternative) => {
      return { name: alternative.answer };
    }) : [],
    childQuestions: question?.subquestion ? [{
      ...createQuestion(question?.subquestion)
    }] : []
 }
}

export function createPayload(questions, conditions, sections, generalForm, isActive) {
  const { name, systems } = generalForm;
  const payloadQuestions = (section) => {
    return questions.filter((question) => question.section.name === section.name).map((question) => {
    return {
      ...createQuestion(question),
    };
  })};
  return {
    conditions: conditions.map((condition) => {
      return {
        isActive: true,
        conditionType: condition.conditionType,
        operator: { id: OPERATOR.EQUAL },
        value: condition.value.id,
      };
    }),
    name,
    isActive: isActive,
    sections: sections.map((section) => {
      return { name: section.name, questions: payloadQuestions(section) };
    }),
    systems: systems.map((systemId) => ({id: systemId}))
  };
}

export function parsePayload(payload) {
  const { conditions, sections, name, systems } = payload;

  const questions = sections.flatMap((section, index) =>
    section.questions.map((question) => {
      return {
        description: question.description,
        name: question.name,
        isRequired: question.isRequired,
        questionType: question.questionType,
        section: { name: section.name, index },
        answers: question.alternatives.map((alternative) => {
          return { answer: alternative.name };
        }),
      };
    })
  );

  const parsedConditions = conditions.map((condition) => {
    return {
      conditionType: condition.conditionType,
      value: { id: condition.value }, // Adaptar ao formato original esperado
    };
  });

  const parsedSections = sections.map((section, index) => {
    return {
      name: section.name,
      index
    };
  });

  const parsedSystems = systems.map((system) => {
    return system.id;
  });

  return {
    questions,
    conditions: parsedConditions,
    sections: parsedSections,
    name,
    systems: parsedSystems
  };
}
