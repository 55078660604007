import { useState, useEffect } from "react";
import { Row } from "../row/row";
import {
  Button,
  FormControl,
  Input,
  Switch,
  Select,
  IconButton,
  Modal,
  ProgressIndicator
} from "fiorde-fe-components";
import { t } from "i18next";
import { makeSelectValues } from "@/presentation/utils";
import { initialQuestionForm } from "@/presentation/pages/checklist-add/page";
import { addConditional } from "./checklist-helpers";
import { CHECKLIST_TYPE } from "@/main/enums/checklist"
import { TrashIcon } from "../icons";
import { RemoveMultipleChoice } from "../remove-checklist-multiple-choice/remove-checklist-multiple-choice";
import { CHECKLIST_SECTIONSQUESTIONS_BUTTON_ADDQUESTION, CHECKLIST_QUESTIONS_MODAL, CHECKLIST_QUESTIONFORM_BUTTON_ADDCHOICE, CHECKLIST_QUESTIONFORM_BUTTON_CLOSE, CHECKLIST_QUESTIONFORM_BUTTON_SUBMIT, CHECKLIST_QUESTIONFORM_INPUT_CHOICE, CHECKLIST_QUESTIONFORM_INPUT_CHOICE_LABEL, CHECKLIST_QUESTIONFORM_INPUT_DESCRIPTION, CHECKLIST_QUESTIONFORM_INPUT_DESCRIPTION_LABEL, CHECKLIST_QUESTIONFORM_INPUT_NAME, CHECKLIST_QUESTIONFORM_INPUT_NAME_LABEL, CHECKLIST_QUESTIONFORM_LINK_ADDCHOICE, CHECKLIST_QUESTIONFORM_LINK_NO, CHECKLIST_QUESTIONFORM_LINK_YES, CHECKLIST_QUESTIONFORM_SELECT_TYPE, CHECKLIST_QUESTIONFORM_SELECT_TYPE_LABEL, CHECKLIST_QUESTIONFORM_SWITCH_CONDITIONAL, CHECKLIST_QUESTIONFORM_SWITCH_CONDITIONAL_LABEL, CHECKLIST_QUESTIONFORM_SWITCH_MANDATORY, CHECKLIST_QUESTIONFORM_SWITCH_MANDATORY_LABEL } from "@/ids";

export function ChecklistQuestionForm({
  open,
  setOpen,
  sectionEditable,
  values,
  typeFieldList,
  onChange,
  onSubmit,
  onClose,
  disabledCloseButton,
  saveConditional,
  conditional,
  setConditional
}) {
  const [isLoading, setLoading] = useState(false);
  const [answers, setAnswers] = useState((values.index !== null && values.answers) ? [...values.answers] : []);

  const [data, setData] = useState(values.index !== null ? { ...values, section: sectionEditable } : {...initialQuestionForm, section: sectionEditable})

  useEffect(() => {
    if(open) {
      setData(values.index !== null ? { ...values, section: sectionEditable } : {...initialQuestionForm, section: sectionEditable})
      setAnswers((values.index !== null && values.answers) ? [...values.answers] : [])
    } else {
      onChange(initialQuestionForm)
      setConditional(null)
    }
  }, [open])

  const onAddConditional = (indexQuestion: string, indexAnswer: string, section: number) => {
    addConditional(indexQuestion, indexAnswer, section, setOpen, setConditional)
  }

  const multipleChoiceAnswer = [
    {
      onAddConditional,
      answer: "",
      isThereSubQuestion: false,
    },
    {
      onAddConditional,
      answer: "",
      isThereSubQuestion: false,
    },
  ];

  const yesNoAnswer = [
    {
      onAddConditional,
      answer: t("components.add-checklist.yes-label"),
      isThereSubQuestion: false,
    },
    {
      onAddConditional,
      answer: t("components.add-checklist.no-label"),
      isThereSubQuestion: false,
    },
  ];

  function changeType(type: number): void {
    if (type === CHECKLIST_TYPE.MULTIPLE_CHOICE) {
      setAnswers([...multipleChoiceAnswer]);
      setData({
        ...data,
        answers: [...multipleChoiceAnswer],
        questionType: { id: type },
      })
    }
    if (type === CHECKLIST_TYPE.YES_NO) {
      setAnswers([...yesNoAnswer]);
      setData({
        ...data,
        answers: [...yesNoAnswer],
        questionType: { id: type },
      })
    } else {
      setData({
        ...data,
        questionType: { id: type },
      })
    }
  }

  const removeMultipleChoice = (index: number) => {
    const newAnswers = answers.filter((_answer, i) =>  i !== index)
    setAnswers([...newAnswers])
    setData({...data, answers: newAnswers})
  }

  const submitData = (data) => {
    if(conditional !== null) {
      saveConditional(data, conditional)
    } else {
      onSubmit(data); 
    }
    handleClose();
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setData(initialQuestionForm)
  }

  return (
    <>
    <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <Button
        id={CHECKLIST_SECTIONSQUESTIONS_BUTTON_ADDQUESTION}
        data-testid="new-question-button"
        onAction={handleOpen}
        text={t("components.add-checklist.new-question-button")}
        backgroundGreen={true}
      />
      <Modal
        id={CHECKLIST_QUESTIONS_MODAL}
        width={600}
        title={'Nova pergunta'}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
          id={CHECKLIST_QUESTIONFORM_BUTTON_CLOSE}
          backgroundGreen={false}
          onAction={onClose}
          text="Fechar"
          disabled={disabledCloseButton}
        />,
        <Button
          id={CHECKLIST_QUESTIONFORM_BUTTON_SUBMIT}
          onAction={() => submitData(data)}
          disabled={false}
          text={data.index !== null ? t("components.add-checklist.edit-question") : t("components.add-checklist.add-question") }
        />,
        ]}
      >
      <p>{t("components.add-checklist.question")} {values.index !== null && (values.index + 1)}</p>
      <Row>
        <FormControl
          label={t("components.add-checklist.question-text-label")}
          id={CHECKLIST_QUESTIONFORM_INPUT_NAME_LABEL}
        >
          <Input
            inputProps={{ "data-testid": "question-text" }}
            id={CHECKLIST_QUESTIONFORM_INPUT_NAME}
            onChange={(value: string) =>
              setData({...data, name: value })
            }
            value={data.name}
            placeholder={t(
              "components.add-checklist.question-text-placeholder"
            )}
          />
        </FormControl>
      </Row>
      <Row>
        <FormControl
          label={t("components.add-checklist.mandatory")}
          id={CHECKLIST_QUESTIONFORM_SWITCH_MANDATORY_LABEL}
        >
          <Switch
            id={CHECKLIST_QUESTIONFORM_SWITCH_MANDATORY}
            label={
              data.isRequired
                ? t("components.add-checklist.mandatory-answer")
                : t("components.add-checklist.non-mandatory-answer")
            }
            checked={data.isRequired}
            onChange={() =>
              setData({...data, isRequired: !data.isRequired })
            }
          />
        </FormControl>
      </Row>
      <Row gridTemplateColumns="1fr 2fr">
        <FormControl
          label={t("components.add-checklist.type-field-label")}
          id={CHECKLIST_QUESTIONFORM_SELECT_TYPE_LABEL}
          >
          <Select
            id={CHECKLIST_QUESTIONFORM_SELECT_TYPE}
            name="type"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: typeFieldList,
            })}
            onChange={(value) => {
              changeType(value);
            }}
            placeholder={t("components.add-checklist.select")}
            value={data?.questionType?.id}
          />
        </FormControl>
      </Row>
      <Row>
        <FormControl
          label={t("components.add-checklist.description-label")}
          id={CHECKLIST_QUESTIONFORM_INPUT_DESCRIPTION_LABEL}
        >
          <Input
            inputProps={{ "data-testid": "description" }}
            id={CHECKLIST_QUESTIONFORM_INPUT_DESCRIPTION}
            onChange={(value: string) =>
              setData({...data, description: value })
            }
            value={data.description}
            placeholder={t("components.add-checklist.description-placeholder")}
          />
        </FormControl>
      </Row>
      {data?.questionType?.id === CHECKLIST_TYPE.MULTIPLE_CHOICE && (
        <>
          {t("components.add-checklist.multiple-choice")}
          {answers.map((choice, index) => (
            <Row style={{ marginTop: "10px" }} key={index}>
              <FormControl
                label={`${t("components.add-checklist.multiple-choice")} ${
                  index + 1
                }`}
                id={CHECKLIST_QUESTIONFORM_INPUT_CHOICE_LABEL}
              >
                <>
                <div style={{display: 'grid', gridTemplateColumns: '1fr auto', gap: '8px'}}>
                  <Input
                    inputProps={{ "data-testid": "multiple-choice" }}
                    id={CHECKLIST_QUESTIONFORM_INPUT_CHOICE}
                    onChange={(value: string) => {
                      const choices = [...answers];
                      choices[index].answer = value;
                      // onChange({ ...values, answers: [...choices] });
                      setData({...data, answers: [...choices] })
                    }}
                    value={choice.answer}
                    placeholder=""
                  />
                  <RemoveMultipleChoice
                    removeMultipleChoice={removeMultipleChoice}
                    index={index}
                  />
                </div>
                </>
              </FormControl>
            </Row>
          ))}
          <Button
            backgroundGreen={false}
            id={CHECKLIST_QUESTIONFORM_BUTTON_ADDCHOICE}
            onAction={() => {
              const choices = [...data?.answers];
              choices.push("");
              setAnswers((prev) => [
                ...prev,
                {
                  answer: "",
                  isThereSubQuestion: false,
                },
              ]);
              onChange({ ...values, answers: [...choices] });
            }}
            disabled={false}
            text={t("components.add-checklist.add-multiple-choice")}
          />
        </>
      )}
    </Modal>
    </>
  );
}
